// app/javascript/controllers/section_controller.js
import { Controller} from "stimulus"

export default class extends Controller {
    static targets = ["standardDeliveryOption", "standardDeliveryOptionDetail", "ownDeliveryOption",
        "forPickupOption", "mainStreet", "countryId", "city", "deliveryMethod"]

    distanceLimit = null;

    connect() {
        document.addEventListener("map:clicked", this.showSection.bind(this));
        this.fetchDistanceLimit();
    }

    disconnect() {
        document.removeEventListener("map:clicked", this.showSection.bind(this));
    }

    showSection() {
        this.element.classList.remove("d-none");

        const { distance } = event.detail;

        if (distance <= this.distanceLimit) {
            this.standardDeliveryOptionTarget.classList.add("d-none");
            this.standardDeliveryOptionDetailTarget.classList.add("d-none");
            this.ownDeliveryOptionTarget.classList.remove("d-none");
            this.countryIdTarget.setAttribute("readonly", true);
            this.mainStreetTarget.setAttribute("readonly", true);
            this.cityTarget.setAttribute("readonly", true);
            this.deliveryMethodTarget.value = "OwnDelivery"
        } else {
            this.standardDeliveryOptionTarget.classList.remove("d-none");
            //this.standardDeliveryOptionDetailTarget.classList.remove("d-none");
            this.ownDeliveryOptionTarget.classList.add("d-none");
            this.countryIdTarget.setAttribute("readonly", false);
            this.mainStreetTarget.setAttribute("readonly", false);
            this.deliveryMethodTarget.value = "AexDelivery"
        }
    }

    async fetchDistanceLimit() {
        try {
            const response = await fetch('/delivery/own_delivery_limit/');
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            this.distanceLimit = data.distance_limit;
            console.log('Límite de distancia obtenido:', this.distanceLimit);
        } catch (error) {
            console.error('Error al obtener el límite de distancia:', error);
        }
    }
}
