// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "bootstrap"
import "../stylesheets/application"

// jquery
var jQuery = require('jquery');



global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("jquery-ui")
require("jquery-mask-plugin")
// require("@nathanvda/cocoon");

//import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap';

import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop'
//carousel
// import owlCarousel from "owl.carousel2";
// import "owl.carousel2/dist/assets/owl.carousel.css";
Rails.start()
//Turbolinks.start()
ActiveStorage.start()
ActiveStorageDragAndDrop.start()


global.toastr = require("toastr")

$(function(){
  console.log('Loaded.');
});

$(function() {
    $(".images").sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


    $( "#sortable" ).sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


  });
  $.jMaskGlobals.watchDataMask = true;
require("trix")
require("@rails/actiontext")
import "controllers"
import SimpleBar from 'simplebar';

const initialize = () => {
  initializeSimplebar();
  initializeSidebarCollapse();
}

const initializeSimplebar = () => {
  const simplebarElement = document.getElementsByClassName("js-simplebar")[0];

  if(simplebarElement){
      const simplebarInstance = new SimpleBar(document.getElementsByClassName("js-simplebar")[0]);

      /* Recalculate simplebar on sidebar dropdown toggle */
      const sidebarDropdowns = document.querySelectorAll(".js-sidebar [data-bs-parent]");

      sidebarDropdowns.forEach(link => {
          link.addEventListener("shown.bs.collapse", () => {
              simplebarInstance.recalculate();
          });
          link.addEventListener("hidden.bs.collapse", () => {
              simplebarInstance.recalculate();
          });
      });
  }
}

const initializeSidebarCollapse = () => {
  const sidebarElement = document.getElementsByClassName("js-sidebar")[0];
  const sidebarToggleElement = document.getElementsByClassName("js-sidebar-toggle")[0];

  if(sidebarElement && sidebarToggleElement) {
      sidebarToggleElement.addEventListener("click", () => {
          sidebarElement.classList.toggle("collapsed");

          sidebarElement.addEventListener("transitionend", () => {
              window.dispatchEvent(new Event("resize"));
          });
      });
  }
}

// Wait until page is loaded
document.addEventListener("DOMContentLoaded", () => initialize());
// window.initMap = function(...args){
//   const event = document.createEvent("Events")
//   event.initEvent("google-maps-callback", true, true)
//   event.args = args
//   window.dispatchEvent(event)
// }
window.dispatchMapsEvent = function (...args) {
    const event = new CustomEvent("google-maps-callback", {
        detail: args, // Pasar argumentos usando la propiedad 'detail'
        bubbles: true, // Permite que el evento se propague hacia arriba en el árbol del DOM
        cancelable: true // Permite que el evento sea cancelable
    });

    window.dispatchEvent(event);
}
