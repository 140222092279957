// Example adapted from https://www.driftingruby.com/episodes/google-maps-api-with-stimulusjs
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["field", "map", "latitude", "longitude", "distance", "originLatitude", "originLongitude"]

    connect() {
        if (typeof (google) != "undefined"){
            this.initializeMap()
        }
    }

    initializeMap() {
        this.map()
        this.marker()
        this.autocomplete()
        console.log('init Map')
    }

    map() {
        if(this._map === undefined) {
            this._map = new google.maps.Map(this.mapTarget, {
                center: new google.maps.LatLng(
                    parseFloat(this.originLatitudeTarget.value) || -25.28646,
                    parseFloat(this.originLongitudeTarget.value) || -57.647
                ),
                zoom: 12
            })

            this._map.addListener('click', this.handleMapClick.bind(this));
        }
        return this._map
    }

    marker() {
        if (this._marker === undefined) {
            this._marker = new google.maps.Marker({
                map: this.map(),
                anchorPoint: new google.maps.Point(0,0)
            })
            let mapLocation = {
                lat: parseFloat(this.latitudeTarget.value),
                lng: parseFloat(this.longitudeTarget.value)
            }
            this._marker.setPosition(mapLocation)
            this._marker.setVisible(true)
        }
        return this._marker
    }

    autocomplete() {
        if (this._autocomplete === undefined) {
            this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
            this._autocomplete.bindTo('bounds', this.map())
            this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
            this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
        }
        return this._autocomplete
    }

    locationChanged() {
        let place = this.autocomplete().getPlace()

        if (!place.geometry) {
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }

        const destination = place.geometry.location

        this.map().fitBounds(place.geometry.viewport)
        this.map().setCenter(place.geometry.location)
        this.marker().setPosition(place.geometry.location)
        this.marker().setVisible(true)

        this.latitudeTarget.value = place.geometry.location.lat()
        this.longitudeTarget.value = place.geometry.location.lng()


        // call to calculateDistance with new location
        this.calculateDistance(origin, destination);
    }

    preventSubmit(e) {
        if (e.key === "Enter") { e.preventDefault() }
    }


    moveMarker(location) {
        if (this._marker === undefined) {
            this.marker();
        }
        this._marker.setPosition(location);
        this._marker.setVisible(true);
        this.latitudeTarget.value = location.lat()
        this.longitudeTarget.value = location.lng()
    }

    calculateDistance(origin, destination) {
        return new Promise((resolve, reject) => {
            const service = new google.maps.DistanceMatrixService();

            service.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: google.maps.TravelMode.DRIVING,
            }, async (response, status) => { // Aquí el async es opcional a menos que necesites usar await dentro de este callback
                if (status !== 'OK') {
                    reject('Error was: ' + status);
                } else {
                    const distanceInMeters = response.rows[0].elements[0].distance.value;
                    const distance = distanceInMeters / 1000
                    console.log(`Distance: ${distance} kilometers.`);
                    this.distanceTarget.value = distance;
                    resolve(distance);
                }
            });
        });
    }

    async handleMapClick(mapsMouseEvent) {
        const clickedLocation = mapsMouseEvent.latLng;
        let originLatitude = this.originLatitudeTarget.value
        let originLongitude = this.originLongitudeTarget.value
        const origin = new google.maps.LatLng(originLatitude, originLongitude);

        this.moveMarker(clickedLocation);
        try {
            let distance = await this.calculateDistance(origin, clickedLocation);
            const event = new CustomEvent("map:clicked", { bubbles: true,
                detail: {
                    distance: distance
                }
            });
            this.element.dispatchEvent(event);
        } catch (error) {
            console.error("Error calculating distance: ", error);
        }
    }
}